import React, { useState } from 'react'
import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
// import EnquireForm from './EnquireForm'
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import EmailIcon from '@mui/icons-material/Email';
import InstagramIcon from '@mui/icons-material/Instagram';
import styles from './App.module.scss'


function App() {
  const [enquireFormToggled, toggleEnquireForm] = useState(false)
  const instagramUrl = 'https://www.instagram.com/maddiesmickeymagic/'
  return (
    <div className="App">
      {/* <Grid>
        <h1 className={styles.header}>MMM</h1>
      </Grid> */}
      <Grid
        container
        spacing={2}
        justifyContent="center"
        classes={{ root: styles.container }}
      >
        <Grid item xs={12}>
          <div className={styles.buttonWrapper}>
            <Button
              classes={{ root: styles.button }}
              startIcon={<InstagramIcon />}
              variant="outlined"
              onClick={() => window.location.href = instagramUrl}
              size="large"  
            >
              INSTAGRAM
            </Button>
          </div>
        </Grid>
        {/* <Grid item xs={12}>
          <div className={styles.buttonWrapper}>
            <Button
              classes={{ root: styles.button }}
              startIcon={<EmailIcon />}
              variant="outlined"
              onClick={() => console.log("BOOKINGS")}
              size="large"
            >
              BOOK A TRIP
            </Button>
          </div>
        </Grid> */}
        <Grid item xs={12}>
          <div className={styles.buttonWrapper}>
            <Button
              classes={{ root: styles.button }}
              startIcon={<LocalPhoneIcon />}
              variant="outlined"
              onClick={() => console.log("CALL")}
              href="tel:6018269920"
              size="large"
            >
              CALL ME
            </Button>
          </div>
        </Grid>
      </Grid>
    </div>
  )
}
//       <Grid container spacing={2}>
//         <Grid item xs={12} className={styles.headerContainer}>
//           {/* <img src={mickey_head_outline} /> */}
//           <h1 className="letterpress">Maddie Mouse</h1>
//           <h1 className="letterpress">Travel</h1>
//         </Grid>
//         <hr className={styles.goldRule} />
//         {/* <Grid item xs={12}>
//           <Actions toggleEnquireForm={toggleEnquireForm} enquireFormToggled={enquireFormToggled} />
//         </Grid> */}
//         <Grid item xs={12}>
//           <div className={styles.rowInfo}>
//             <img className={styles.profilePicture} src="https://scontent-mia3-1.cdninstagram.com/v/t51.2885-19/200467431_332605068246841_426008585982768224_n.jpg?stp=dst-jpg_s320x320&_nc_ht=scontent-mia3-1.cdninstagram.com&_nc_cat=101&_nc_ohc=bQG0a_EIZTIAX9lekn0&edm=AOQ1c0wBAAAA&ccb=7-5&oh=00_AfAqN2OxScuNgsdJkA9-ROYW_eDalMoZq2Y2TZleK_GKpg&oe=63E7FEE2&_nc_sid=8fd12b" />
//             <h2>Let me book your trip!</h2>
//             <p>Best rates</p>
//             <p>Day to day itinerarys</p>
//             <Button variant="outlined" onClick={() => toggleEnquireForm(!enquireFormToggled)}>Get Started</Button>
//             {enquireFormToggled && (
//               <EnquireForm />
//             )}
//           </div>
//         </Grid>
//         <hr className={styles.goldRule} />
//         <Grid item xs={12}>
//           <div className={styles.rowInfo}>
//             <h2>Fun for longer</h2>
//             <p>Check out some excited items to keep the fun going for longer.</p>
//             <p>Save on items before you get there!</p>
//             <Button variant="outlined" onClick={() => console.log('take me to shop')}>Shop</Button>
//           </div>
//         </Grid>
//       </Grid>
//     </div>
//   );
// }

export default App;
